<template>
<div>
  <b-table class="table-devices" bordered :items="this.devicesRows" small :per-page="perPage"
           :current-page="currentPage" :fields="fields" >
    <template #cell()="row">
      <div v-if="row.value">
        <span class="label-sm">{{row.field.label}}: </span> <span :class="{'ip-cell': row.field.key === 'ip'}">{{ row.value }}</span>
      </div>
      <div v-else class="dontShow">-</div>
    </template>

  </b-table>
  <b-pagination
      class="m-t-15 m-b-10"
      v-if="rows > perPage"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
  ></b-pagination>
</div>
</template>

<script>
import Device from "@/components/settings/Device.vue";
import moment from "moment";
export default {
  name: "Devices",
  components: {Device},
  props: ['devices'],
  data() {
    return {
      currentPage: 1,
      perPage: 5
    }
  },
  computed: {
    rows() {
      return this.devices.length
    },
    devicesRows() {
      return this.devices.map(item => {
        return {
          os_version: item.os_version,
          ip: item.last_ip,
          requested_at: moment(item.requested_at, 'X').format('DD.MM.YYYY, HH:mm'),
          app_version: item.app_version,
          last_search_at: item.last_search_at
              ? moment(item.last_search_at, 'X').format('DD.MM.YYYY, HH:mm') : '',
          created_at: moment(item.created_at, 'X').format('DD.MM.YYYY, HH:mm'),
        }
      })
    },
    fields() {
      return [
        {
          key: 'os_version',
          label: this.$t('device.os_version')
        },
        {
          key: 'requested_at',
          label: this.$t('device.requested_at')
        },
        {
          key: 'last_search_at',
          label: this.$t('device.last_search_at'),
          sortable : true
        },
        {
          key: 'ip',
          label: this.$t('device.last_ip')
        },
        {
          key: 'created_at',
          label: this.$t('device.created_at')
        },
        {
          key: 'app_version',
          label: this.$t('device.app_version')
        },


      ]
    },
    paginatedItems() {
      let page_number = this.currentPage-1
      return this.devices.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
    },
  }
}
</script>

<style>
 .table-devices {
   max-width: 100%;
   white-space: break-spaces;
 }
 .table-devices.table.table-sm td {
   white-space: break-spaces;
   padding: 10px 5px;
 }
 .table-devices.table.table-sm th {
   padding: 10px 5px;
 }
 @media (max-width: 1199px) {
   .label-sm {
     display: inline-block;
     font-weight: 600;
   }
   .table-devices.table.table-sm tr {
     display: block;
     border-bottom-width: 0;
     padding-top: 10px;
   }
   .table-devices.table.table-sm tr:first-child {
     border-top-width: 0;
     padding-top: 0;
   }
   .table-devices.table.table-sm td {
     display: block;
     border-left-width: 0;
     border-right-width: 0;
     padding-top: 0;
     padding-bottom: 0;
   }
   .table-devices.table.table-sm th {
     display: none;
     border: none;
   }
   .table-devices.table.table-sm thead  {
     display: none;
   }
   .dontShow {
     display: none;
   }
 }
 @media (min-width: 1200px) {
   .label-sm {
     display: none;
   }

   .table-devices.table.table-sm td {
    text-indent: -5px;
   }
   .dontShow {
     text-align: center;
   }

 }
</style>