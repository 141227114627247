<template>
  <div class="user-detail">
    <div class="card">
      <div v-if="loading">
        <div class="card-body">
          <div class="panel-block">
            <b-skeleton width="20%" :animated="true"></b-skeleton>
          </div>
          <div class="panel-block">
            <b-skeleton width="40%" :animated="true"></b-skeleton>
          </div>
          <div class="panel-block">
            <b-skeleton width="80%" :animated="true"></b-skeleton>
          </div>
          <div class="panel-block">
            <b-skeleton :animated="true"></b-skeleton>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-block table-border-style ">
          <table class="table table-bordered table-user-detail">
            <tr>
              <td>{{ $t('user.first_name') }}:</td>
              <td>
                {{ user.first_name }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('user.last_name') }}:</td>
              <td>
                {{ user.last_name }}
              </td>
            </tr>
            <tr v-if="typeof user.roles !== 'undefined'">
              <td>{{ $t('user.Roles') }}:
              </td>
              <td>
                {{$t('user.'+user.roles)}}
              </td>
            </tr>
            <tr v-if="user.account.is_active">
              <td>{{ $t('users_list_params.expired_at') }}:
              </td>
              <td>
                {{ user.account.expired_at | moment('DD.MM.YYYY')}}
              </td>
            </tr>
            <tr v-else>
              <td>{{ $t('users_list_params.expired_at') }}:
              </td>
              <td><span class="text-danger" style="padding: 0">{{ $t('users_list_params.inactive') }}</span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('users_list_params.balance') }}:
              </td>
              <td>
                {{ user.account.unlimited ? $t('account.unlimited') : user.account.balance + ' ' + $t('users_list_params.credits')}}
              </td>
            </tr>
            <tr>
              <td>{{ $t('users_list_params.limit') }}:
              </td>
              <td>
                {{ user.account.limit }} {{$t('users_list_params.credits')}} / {{user.account.period}} {{ $t('users_list_params.days') }}
              </td>
            </tr>
            <tr v-if="user.hasOwnProperty('permissions') && user.permissions.length > 0">
              <td>{{ $t("user.permissions") }}:
              </td>
              <td>
                {{ user.permissions.map(permission => $t('permissions.'+permission)).join(', ') }}
              </td>
            </tr>
            <tr v-if="typeof user.last_location !== 'undefined' && user.last_location">
              <td>{{ $t("user.geolocation") }}:</td>
              <td>
                {{user.last_location.country}}, {{user.last_location.region}}, {{user.last_location.city}}
                ({{ parseFloat(user.last_location.latitude).toFixed(4) }}, {{ parseFloat(user.last_location.longitude).toFixed(4) }})
              </td>
            </tr>
            <tr v-if="user.first_login_at">
              <td>{{$t('user.first_login')}}:</td>
              <td>
                {{moment(user.first_login_at, 'X').format('DD.MM.YYYY, HH:mm')}}
              </td>
            </tr>
            <tr v-if="user.last_search_at">
              <td>{{$t('user.last_search')}}:</td>
              <td>
                {{moment(user.last_search_at, 'X').format('DD.MM.YYYY, HH:mm')}}
              </td>
            </tr>
            <tr v-if="!und.isUndefined(user.session_history) && user.session_history.length">
              <td style="vertical-align: top">{{ $t('user.session_history') }}:</td>
              <td>
                <sessions :session-history="sessions"/>
              </td>
            </tr>
            <tr v-if="devices.length">
              <td style="vertical-align: top">{{ $t('user.devices_history') }}:</td>
              <td>
                <devices :devices="devices"/>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import _ from "underscore";
import Devices from "@/components/users/Devices.vue";
import Sessions from "@/components/users/Sessions.vue";

export default {
  name: "UserDetail",
  data() {
    return {
      loading: true
    }
  },
  setup(){
    return {
      moment,
      und: _
    }
  },
  components: {
    Sessions,
    Devices,
  },
  computed: {
    ...mapGetters('users',['user']),
    devices() {
      return this.user.devices.sort((a,b) => {return b.last_search_at - a.last_search_at})
    },
    sessions(){
      return this.user.session_history.sort((a,b) => { return b.last_request_at - a.last_request_at })
    }
  },
  mounted() {
    this.$store.dispatch('users/getUser', this.$route.params.id).then(() => {this.loading = false});
  }
}
</script>

<style scoped>
.panel-block b{
  margin-right: 10px;
}
.panel-block {
  color: #363636;
  padding: 0.5em 0.75em;
}

.table-user-detail tr td:first-child{
  width: 160px;
}
.table-user-detail tr td {
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: break-spaces;
  word-break: break-word;
}
.table-user-detail tr td > * {
  padding: 0;
}

@media (max-width: 767px) {
  .table-user-detail tr td:first-child {
    width: auto;
    font-weight: 600;
    border-bottom: 0;
  }
  .table-user-detail tr td:last-child {
    white-space: break-spaces;
    border: 0;
  }
  .table-user-detail tr td {
    display: block;
  }
  .table-user-detail tr {

  }
}
</style>