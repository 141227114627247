<template>
  <div class="device-block">
    <div v-for="(deviceKey, index ) in deviceKeys" :key="index">
      <div v-if="device[deviceKey] && !['requested_at'].includes(deviceKey)">
        <span :class="{'has-text-weight-bold': index===0}">{{ $t('device.'+deviceKey) }}: </span>
        <span v-if="['created_at', 'last_search_at'].includes(deviceKey) && device[deviceKey]">{{moment(device[deviceKey], 'X').format('DD.MM.YYYY, HH:mm')}}</span>
        <span v-else-if="['last_ip'].includes(deviceKey)">{{device[deviceKey] +'   '}}
          <span v-if="device['requested_at']">({{moment(device['requested_at'], 'X').format('DD.MM.YYYY, HH:mm')}})</span>
        </span>
        <span v-else-if="!['requested_at'].includes(deviceKey)">{{ device[deviceKey] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Device",
  props: ['device'],
  setup() {
    return {
      moment
    }
  },
  computed: {
    deviceKeys() {
      let arr = ['os_version'];
      for(let item of Object.keys(this.device)) {
        if(arr.indexOf(item) === -1) {
          arr.push(item);
        }
      }
      let index = arr.indexOf('setup_id');
      if (index !== -1) {
        arr.splice(index, 1);
      }
      return arr;
    }
  },
  methods: {

  }
}
</script>

<style scoped>
  .device-block {
    padding-bottom: 10px;
  }
  .device-block p {
    margin-bottom: 3px;
  }
  .device-block:not(:first-child){
    padding-top: 10px;
    border-top: 1px solid #ededed;
  }

</style>