<template>
  <div class="session-item">IP: {{sessionItem.ip}}, {{sessionItem.country}}, {{sessionItem.region}},
    {{sessionItem.city}} ({{parseFloat(sessionItem.latitude).toFixed(4)}}, {{parseFloat(sessionItem.longitude).toFixed(4)}})
  </div>
</template>

<script>
export default {
  name: "SessionItem",
  props: ['sessionItem']
}
</script>

<style scoped>
.session-item {
  padding-bottom: 10px;
}
.session-item:not(:first-child) {
  padding-top: 10px;
  border-top: 1px solid #ededed;
}
</style>