<template>
<div>
  <b-table bordered class="table-devices" :items="this.sessionsRows" small :per-page="perPage"
           :current-page="currentPage" :fields="fields">
    <template #cell()="row">
      <div v-if="row.value">
        <span class="label-sm">{{row.field.label}}: </span> <span :class="{'ip-cell': row.field.key === 'ip'}">{{ row.value }}</span>
      </div>
      <div v-else style="text-align: center">-</div>
    </template>
    <template #cell(last_request_at)="row">
      <div v-if="row.value" style="white-space: nowrap;padding-left: 4px;padding-right: 4px">
        {{row.value}}
      </div>
    </template>

    <template #cell(created_at)="row">
      <div v-if="row.value" style="white-space: nowrap;padding-left: 4px;padding-right: 4px">
       {{row.value}}
      </div>
    </template>
    <template #cell(location)="row">
      <div v-if="row.value" style="white-space: nowrap;padding-left: 4px;padding-right: 4px">
        {{row.value}}
      </div>
    </template>
  </b-table>
  <b-pagination
      class="m-t-15 m-b-10"
      v-if="rows > perPage"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
  ></b-pagination>
</div>
</template>

<script>
import SessionItem from "@/components/users/SessionItem.vue";
import moment from "moment";

export default {
  name: "Sessions",
  components: {SessionItem},
  props: ['sessionHistory'],
  data() {
    return {
      currentPage: 1,
      perPage: 5,

    }
  },
  computed: {
    rows() {
      return this.sessionHistory.length
    },
    paginatedItems() {
      let page_number = this.currentPage-1
      return this.sessionHistory.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
    },
    sessionsRows() {
      return this.sessionHistory.map(item => {
        return {
          place: `${item.country}, ${item.region}, ${item.city}`,
          created_at: item.created_at ? moment(item.created_at, 'X').format('DD.MM.YYYY, HH:mm'): '',
          ip: item.ip,
          last_request_at: item.last_request_at ? moment(item.last_request_at, 'X').format('DD.MM.YYYY, HH:mm'): '',
          location: `${(this.formatLocation(item.latitude))}, ${this.formatLocation(item.longitude)}`,
          user_agent: item.user_agent
        }
      })
    },
    fields() {
      return [
        {key:'place', label: this.$t('session_item.place')},
        {key:'last_request_at', label: this.$t('session_item.last_request_at'), sortable : true},
        {key:'created_at', label: this.$t('device.created_at')},
        {key:'ip', label: this.$t('session_item.ip')},
        {key:'location', label: this.$t('session_item.location')},
        {key:'user_agent', label: this.$t('session_item.user_agent')},
      ]
    }
  },
  methods: {
    formatLocation(coordinate) {
      return parseFloat(coordinate).toFixed(4)
    }
  }
}
</script>

<style>
.ip-cell {
  white-space: nowrap;
}
</style>